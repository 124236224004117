.auth_validation_code {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    .logo-block {
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
  
      .logo {
        width: 4rem;
        max-width: 100%;
      }
    
      .logo-text {
        color: #ffffff;
        font-size: 1.8rem;
        margin: 0;
      }
    }
  
    &-container {
      background-color: #ffffff;
      padding: 1rem 2rem;
      border-radius: 1rem;
      color: #000000;
      margin: 0 1rem 0 1rem;
      max-width: 100%;
      width: 30rem;
  
      @media (max-width: 480px) {
        padding: 1rem;
      }
  
      .language {
        display: flex;
        margin-bottom: 0.5rem;
        justify-content: end;
      }
    }
  
    &-link_back {
      display: flex;
      justify-content: space-between;
      
      * {
        vertical-align: middle;
      }
    }
  
    .platform-authenticator-button {
      width: 5em;
      height: 5em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }