.applications-page {
  padding-top: 5rem;

  .background-gradient {
    top: 4rem;
  }
  
  .main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 46rem;
    margin: 0 auto;

    .application {
      display: block;
      width: 10rem;
      color: #ffffff !important;
      text-decoration: none !important;
      margin: 1.6rem;
      text-align: center;
      cursor: pointer;

      .application-image {
        display: flex;
        align-items: center;
        margin: 0 auto;
        border-radius: 50%;
        padding: 1.6rem;
        height: 8rem;
        width: 8rem;
        background-color: #5c8798;

        img {
          max-width: 100%;
        }
      }

      .application-text {
        font-size: 1.2rem;
        line-height: 1.4rem;
        margin-top: 0.6rem;
      }

      @media (max-width: 480px) {
        width: 8rem;

        .application-image {
          height: 6rem;
          width: 6rem;
        }

        .application-text {
          font-size: 1rem;
          line-height: 1.2rem;
          margin-top: 0.4rem;
        }
      }
    }
  }
}
