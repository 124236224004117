.auth-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .connected_email {
    margin: 0;
    color: #ffffff;
    text-align: center;
  }

  .logout-jwt {
    color: #ffffff !important;
    display: flex;
    margin-bottom: 1rem;
  }

  .login-header {
    color: #ffffff;
    text-align: center;
  }

  .auth-form {
    background-color: #ffffff;
    padding: 1rem 2rem;
    border-radius: 1rem;
    color: #000000;
    max-width: 100%;
    margin: 0 1rem 0 1rem;
    width: 30rem;
    position: relative;

    @media (max-width: 480px) {
      padding: 1rem;
    }

    .language-switch-container {
      position: absolute;
      right: 1em;
      top: 0.5em;
    }

    .auth-form-title-container {
      display: flex;
      .auth-form-title {
        flex-grow: 1;
        text-align: center;
        margin-bottom: 0 1em 1em 1em;
        font-weight: bold;
      }
    }
    

    .forgot-password-link {
      font-style: italic;
      cursor: pointer;
      margin-top: 0.5rem;
      text-align: center;
    }

    .create-account-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .signup {
      text-decoration: underline;
    }
  }

  .platform-authenticator-button {
    width: 5em;
    height: 5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}