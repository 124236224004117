@import '../../assets/scss/core/variables';

.sign_auth_methods-finalize-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .logo-block {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .logo {
      width: 4rem;
      max-width: 100%;
    }
  
    .logo-text {
      color: #ffffff;
      font-size: 1.8rem;
      margin: 0;
    }
  }
}