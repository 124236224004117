@import '../../assets/scss/core/variables';

.sign_auth_methods-finalize-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  

  .connection-method-form {
    background-color: #ffffff;
    border-radius: 1rem;
    color: #000000;
    max-width: 100%;
    margin: 0 1rem 0 1rem;
    width: 30rem;
    
    .language {
      display: flex;
      margin-top: 0.5rem;
      margin-right: 0.8rem;
      justify-content: end;
    }
  }

  .auth-form {
    background-color: #ffffff;
    padding: 1rem 2rem;
    border-radius: 1rem;
    color: #000000;
    max-width: 100%;
    margin: 0 1rem 0 1rem;
    width: 30rem;

    .auth-form-title {
      text-align: center;
      margin-bottom: 0;
      font-weight: bold;
    }

    .forgot-password-link {
      font-style: italic;
      cursor: pointer;
      margin-top: 0.5rem;
      text-align: center;
    }

    .create-account-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .signup {
      text-decoration: underline;
    }
  }

  .platform-authenticator-button {
    width: 5em;
    height: 5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .connection-method-title {
    color: $primaryColor;
    text-align: center;
    margin-bottom: 0;
  }
  
  
  .connection-method-container {
    display: flex;
    justify-content: center;
    gap: 3rem;

    .connection-method {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 7rem;
      width: 9rem;
      background-color: white;
      border-radius: 2px;
      gap: 0.5rem;
      color: $primaryColor;

      h2 {
        margin-bottom: 0;
        color: $primaryColor;
      }

      &--disabled {
        color: $disabled;
        cursor: not-allowed;
        h2 {
          color: $disabled !important;
        }
      }
    }
  }
}