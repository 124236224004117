@import '../../assets/scss/core/variables';

.application-page {
  padding-top: 5rem;

  .background-gradient {
    top: 4rem;
  }
  
  .main {
    padding: 0 1rem;

    .main-panel {
      background-color: #ffffff;
      width: 40rem;
      max-width: 100%;
      border-radius: 1rem;
      margin: 0 auto;
      padding: 1rem;
    
      .title {
        display: flex;
        align-items: center;
        margin: 0 1rem;

        .title-icon {
          border-radius: 50%;
          height: 3rem;
          width: 3rem;
          padding: 0.4rem;
          background-color: #5c8798;

          img {
            max-width: 100%;
          }
        }

        h2 {
          margin: 0 0 0 1rem;
          color: $primaryColor;
        }
      }

      .sub-title {
        color: $primaryColor;
        border-bottom: 1px solid $primaryColor;
        margin: 1rem 0 0 0;
      }
    }
  }

  .application-submit {
    margin-top: 1rem;
    text-align: center;
    .application-field {
      display: flex;
      align-items: flex-end;
      margin-bottom: 1rem;
  
      &-input {
        flex-grow: 1;
        margin: 1rem 0 0 0;
      }
  
      &-share {
        margin: 1rem 0 0 1rem;
      }
    }
  }
}
