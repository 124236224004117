@import '../../../assets/scss/core/variables';

.application-image {
  margin: 0 auto;
  border-radius: 50%;
  padding: 0.4rem;
  height: 3.2rem;
  width: 3.2rem;
  background-color: #5c8798;
  display: inline-block;
  margin-right: 1rem;

  img {
    max-width: 100%;
  }
}

.share_data_action {
  color: $primaryColor;
  font-size: 1.2rem;
  margin-top: 0.6rem;
  border-bottom: 1px solid #5c8798;
}
