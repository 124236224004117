@import '../../assets/scss/core/variables';

.share_data_page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo-block {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;

    .logo {
      width: 4rem;
      max-width: 100%;
    }
  
    .logo-text {
      color: #ffffff;
      font-size: 1.8rem;
      margin: 0;
    }
  }

  h1 {
    color: #ffffff;
  }

  .share_data_panel {
    background-color: #ffffff;
    padding: 1rem 2rem;
    border-radius: 1rem;
    color: #000000;
    max-width: 100%;
    margin: 0 1rem 0 1rem;
    width: 30rem;

    .share_data_title {
      font-size: 1.4rem;
      color: $primaryColor;

      * {
        vertical-align: middle;
      }
    }

    .share_data_invalid_container {
      width: 16rem;
      text-align: center;

      .share_data_invalid_text {
        font-size: 1.6rem;
      }
    }

    .share_data_actions {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
    }
  }
}