@import './assets/scss/core/variables';

body {
  min-height: 100%;
  background: $primaryColor;
  color: #ffffff;
}

.background-gradient {
  background: linear-gradient(0deg, RGBA(29, 73, 91, 0.5) 0%, #5b8c9e 100%);
  height: 20em;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.w-2rem {
  width: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.bold {
  font-weight: bold;
}

.logo-block {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .logo {
    width: 4em;
    max-width: 100%;
  }

  .logo-text {
    color: #ffffff;
    font-size: 1.8em;
    margin: 0;
  }
}

.signup-header-container {
  padding-bottom: 0.5rem;
  text-align: center;

  .signup-header-title {
    margin-bottom: 0;
    color: #ffffff;
  }

  .signup-header-step {
    font-style: italic;
    color: #ffffff;
  }
}


.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
          animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.d-flex {
  display: flex;
}

.d-flex-center {
  justify-content: center;
}

.d-flex-colmn {
  flex-direction: column;
}

.d-flex-middle {
  align-items: center;
}

.g-1 {
  gap: 1em;
}

.black {
  color: black;
}