.share_data_auth_actions {
  margin-top: 2rem;
  text-align: center;
}

.share_data_success {
  text-align: center;

  .share_data_success_header {
    font-size: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      font-size: 3em;
    }
  }

  .share_data_success_button {
    margin: 1em 1em 0 1em;
  }
}
