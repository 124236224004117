@import '../../../assets/scss/core/variables';

.pin-feedback {
  text-align: center;

  div {
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    border: 1px solid $primaryColor;
    border-radius: 50%;
    margin: 0 0.5em;

    &.full {
      background-color: $primaryColor;
    }
  }
}
