@import '../../../assets/scss/core/variables';

.ant-btn.pin_button {
  background-color: #ffffff;
  color: $primaryColor;
  border: 1px solid $primaryColor;
  display: inline-flex;
  height: 2em;
  width: 2em;
  font-size: 2em;
  margin: 0 0.5em 1em 0.5em;
  vertical-align: middle;
  align-items: center;
  justify-content: center;

  &:active {
    background-color: $primaryColor;
    color: #ffffff;
    border: 1px solid $primaryColor;
  }
}

.pin_empty_space {
  display: inline-flex;
  height: 2em;
  width: 2em;
  margin: 0 0.5em 1em 0.5em;
  vertical-align: middle;
}
